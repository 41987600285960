import React from 'react'

// Components
import Card from '../components/card';
import Title from '../components/title';

// Styles
import styles from '../styles/scss/components/recommendedForYou.module.scss'

const RecommendedForYou = ({
    products,
}) => {

    return (
        <div
            className={styles.RecommendedForYouMainContainer}
        >
            {/* Title */}
            <Title
                className={styles.MainTitle}
                value="RECOMMENDED FOR YOU"
            />

            {/* Grid Container */}
            <div
                className={styles.MainGridContainer}
            >
                {
                    products.map((product, key) => (
                        <Card
                            key={key}
                            type="product"
                            title={product.displayName}
                            image={product.listImage.url}
                            slug={product.slug}
                            product={product}
                        >
                            <p>
                                {product.teaser}
                            </p>
                        </Card>
                    ))
                }
            </div>
        </div>
    );
}

export default React.memo(RecommendedForYou);