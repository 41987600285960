import React from 'react';
import { buildURL } from "react-imgix";

// Components
import Card from '../components/card';
import Title from '../components/title';

// Styles
import styles from '../styles/scss/components/everyUse.module.scss'


const EveryUse = ({
    videos,
    posts,
}) => {

    return (
        <div
            className={styles.EveryUseMainContainer}
        >
            {/* Title */}
            <Title
                className={styles.MainTitle}
                value="EVERYDAY USES FOR WD-40 BRAND PRODUCTS"
            />

            {/* Grid Container */}
            <div
                className={styles.MainGridContainer}
            >
                {/* Videos */}
                {
                    videos.map((item, idx) => (
                        <Card
                            key={`video:${idx}`}
                            id={`middleb-${idx}`}
                            type="video"
                            title={item.title}
                            youtubeVideoId={item.video.providerUid}
                            image={item.video.thumbnailUrl}
                            customThumbnail={item.customThumbnail ? item.customThumbnail.url : ""}
                        />
                    ))
                }

                {/* Posts */}
                {
                    posts.map((item, idx) => (
                        <Card
                            key={`post:${idx}`}
                            type="image"
                            title={`${item.title}: ${item.subtitle}`}
                            image={buildURL(item.heroImage.url, { w: 500, bri: -15, fm: 'jpg' })}
                            link={'/article/' + item.slug}
                        >
                            <p>{item.teaser}</p>
                        </Card>
                    ))
                }
            </div>
        </div>
    );
}

export default React.memo(EveryUse);