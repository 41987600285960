import React from 'react';

// Styles
import styles from '../styles/scss/components/newHeroLinks.module.scss'

const HeroLinks = ({ links, heroLinkClick, selectedTab}) => {
  return (
    <div className={styles.linkContainer}>
      {
        links.map(link => (
          link !== undefined && (
            <div key={link.id} className={`${styles.linkItem} ${selectedTab === link.id ? styles.active : ''}`}>
              <button
                tabIndex={0}
                role="button"
                onKeyDown={e => {
                  console.log('MMMM', e.key)
                  if (e.key !== "Tab" && e.key !== "Shift") {
                    heroLinkClick(link.id)
                  }

                }}
                onClick={() => { heroLinkClick(link.id) }}
              >
                {`Solutions\n for ${link.title}`}
              </button>
          </div>
          )
        ))
      }
    </div>
  );
}

export default React.memo(HeroLinks);
