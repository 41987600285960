import React, { Component } from "react";
import { find as _find, random as _rand } from 'lodash'

// Components
import NewLayout from "../layout/newLayout"
import SEO from "../components/seo"
import CtaBanner from "../components/ctaBanner"
import Hero from "../components/hero"
import RecommendedForYou from "../components/recommendedForYou";
import EveryUse from "../components/everyUse";
import AdBlock from "../components/adBlock";

// Services
import { priceSpiderRebind } from "../services/util"
import { heroesForUseType, productsForUseType } from '../services/util'

export default class IndexPage extends Component {

    constructor(props, context) {

        super(props, context)
        let defaultUseType = props.pageContext.landings[2]

        let heroLinks = props.pageContext.landings.map((l) => {
            if (l.id !== "112955144" && l.id !== "872475") {
                return {
                    id: l.id,
                    title: l.name,
                    slug: l.slug
                }
            }
        })

        props.pageContext.landings.forEach(l => {
            l.heroes = heroesForUseType(l.id, props.pageContext.shared.heroes)
            l.products = productsForUseType(l.id, props.pageContext.shared.products)
        })

        this.state = {
            defaultUseType: defaultUseType,
            featuredProducts: [],
            isAllDataReady: false,
            heroInformation: null,
            selectedTab: null,
            heroLinks: heroLinks,
            isDegreaserModalOpen: true,
        }
    }

    async componentDidMount() {
        var defaultType = this.state.defaultUseType.id
        this.selectType(defaultType)
        this.setState(
            { isAllDataReady: true }
        );
        const pricespiderscript = document.createElement("script")
        pricespiderscript.src = "//cdn.pricespider.com/1/lib/ps-widget.js"
        pricespiderscript.async = true
        document.body.appendChild(pricespiderscript)
    }

    componentDidUpdate() {
        priceSpiderRebind()
    }

    selectType = (typeId) => {
        const { pageContext: { landings } } = this.props;
        const selected = _find(landings, { id: typeId });
        if (selected) {
            const hero = this.randomFromArray(selected.heroes);
            const shuffledProductsArray = [...selected.products];
            for (let i = shuffledProductsArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffledProductsArray[i], shuffledProductsArray[j]] = [shuffledProductsArray[j], shuffledProductsArray[i]];
            }
            const featuredProducts = shuffledProductsArray.slice(0, 4);

            this.setState({
                heroInformation: {
                    image: hero.heroImage,
                    mobileImage: hero.mobileHeroImage,
                    title: hero.title,
                },
                featuredProducts: featuredProducts,
                selectedTab: typeId,
            });
        }
    }

    randomFromArray = (array) => {
        return array[_rand(array.length - 1)]
    }

    render() {
        const { pageContext: { page: pg } } = this.props;
        const { featuredProducts, heroInformation, selectedTab, heroLinks } = this.state;
        const { relatedVideos: everyUseVideos, relatedArticles: everyUseArticles, adBlocks } = pg;

        return (
            <NewLayout>
                {
                    pg.seo.title && (
                        <h1 style={{ display: 'none' }}>
                            {pg.seo.title}
                        </h1>
                    )
                }

                <SEO
                    page={pg}
                />

                <Hero
                    heroInformation={heroInformation}
                    selectedTab={selectedTab}
                    heroLinks={heroLinks}
                    heroLinkClick={this.selectType}
                />

                <RecommendedForYou
                    products={featuredProducts}
                />
                <AdBlock data={adBlocks} />


                <EveryUse
                    videos={everyUseVideos}
                    posts={everyUseArticles}
                />

                <CtaBanner
                    text="WANT TO GET MORE TIPS AND TRICKS?"
                    buttonText="SUBSCRIBE TO THE NEWSLETTER"
                    buttonUrl="/signup/"
                />
            </NewLayout>
        )
    }
}