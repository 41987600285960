import React from 'react';

// Components
import NewHeroHeader from './newHeroHeader';
import HeroLinks from './newHeroLinks';

const Hero = ({
  heroInformation,
  selectedTab,
  heroLinks,
  heroLinkClick,
}) => {

  return (
    <div className="hero-header">
      <NewHeroHeader
        heroInformation={heroInformation}
      />
      <HeroLinks
        selectedTab={selectedTab}
        links={heroLinks}
        heroLinkClick={heroLinkClick}
      />
    </div>
  );
}

export default React.memo(Hero);
